import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider (https://auth.staging.konekto.be/realms/EuroportsInlandTerminals/.well-known/openid-configuration)
  issuer: 'https://auth.staging.konekto.be/realms/EuroportsInlandTerminals',
  // issuer: 'http://localhost:8442/auth/realms/konekto',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

  postLogoutRedirectUri: window.location.origin,

  // The SPA's id. The SPA is registered with this id at the auth-server
  //clientId: 'spurado-local',
  clientId: 'client:webapp:public',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid email eit:purchase-order.read',//eit:purchase-order.create eit:purchase-order.read eit:purchase-order:greenlight.update',

  strictDiscoveryDocumentValidation: false,

  showDebugInformation: true,

  timeoutFactor: 0.8,
};

