import {AsyncPipe, NgIf} from '@angular/common';
import {Component, DestroyRef, OnInit} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {Router, RouterOutlet} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {OAuthService} from 'angular-oauth2-oidc';
import {EMPTY, switchMap, take, tap} from 'rxjs';
import {HeaderComponent} from './header/header.component';
import {authConfig} from './oidc/auth.config';
import {NotificationActions, UserActions} from './state/euroports.actions';
import {UserState} from './state/user.state';
import {User} from '../api/models/user';
import {filter} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {RoleTopicService} from "../api/ws/services/role-topic.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {routeOfExpectedValidationNotification} from "./in-app-notification/route-expected-validation-notification.pipe";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, AsyncPipe, MatProgressSpinner, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

  loggedUser$ = this.store.select(UserState.currentUser);

  constructor(private oauthService: OAuthService,
              private store: Store,
              private translate: TranslateService,
              private router: Router,
              private snackbar: MatSnackBar,
              private roleTopicService: RoleTopicService,
              private destroyRef: DestroyRef) {
    this.setupAuthConfig();
    this.setupLangConfig();
  }

  ngOnInit(): void {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
        (hasReceivedTokens) => {
          if (hasReceivedTokens) {
            this.store.dispatch(new UserActions.Load());
          }
        }
      );
    } else {
      this.oauthService.loadDiscoveryDocumentAndLogin({state: window.location.pathname}).then(
        () => {
          if (this.oauthService.hasValidAccessToken()) {
            this.store.dispatch(new UserActions.Load());
            const stateUrl = decodeURIComponent(this.oauthService.state!);
            void this.router.navigate([stateUrl]);
          }
        }
      );
    }

    this.store.select(UserState.currentUser).pipe(
      filter(user => this.hasServicesOrRoles(user)),
      take(1),
      switchMap(user => this.roleTopicService.createRoleTopic(user)),
      tap(() => this.store.dispatch(new NotificationActions.Load())),
      switchMap(event => {
        if (event.type == 'VALIDATION_EXPECTED') {
          const snackbarRef = this.snackbar.open(this.translate.instant('notifications.validationRequest'), this.translate.instant('notifications.goto'));
          return snackbarRef.onAction().pipe(
            tap(() => this.router.navigate(routeOfExpectedValidationNotification(event)))
          );
        }
        return EMPTY;
      }),
      // better not open websocket if app.component is destroyed during initialization
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe();
  }

  private hasServicesOrRoles(user: User | undefined): user is User {
    if (!user) {
      return false;
    }
    return !!user.services || !!user.roles;
  }

  private setupAuthConfig() {
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private setupLangConfig() {
    this.translate.addLangs(['fr','nl','en'])
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
  }
}
