@if (loggedUser$ | async) {
  <euroports-header/>

  <main>
    <router-outlet></router-outlet>
  </main>
} @else {
  <div class="full-screen-loader">
    <mat-spinner></mat-spinner>
  </div>
}
