import {Routes} from '@angular/router';
import {purchaseOrderResolver} from './resolvers';
import {isAccountant, isAdmin, isDirector, isQhse, isQhseOrDirector} from './guards';

export const routes: Routes = [
  {
    path: 'purchase-order',
    children: [
      {
        path: ':uuid',
        resolve: {purchaseOrder: purchaseOrderResolver},
        children: [
          {
            path: 'validation',
            children: [
              {
                path: 'amount',
                loadComponent: () => import('./purchase-order-validation/purchase-order-validation.component'),
                canActivate: [isDirector],
                data: {validationType: 'amount'}
              },
              {
                path: 'green-lights',
                loadComponent: () => import('./purchase-order-validation/purchase-order-validation.component'),
                canActivate: [isQhse],
                data: {validationType: 'green-lights'}
              }
            ]
          },
          {
            path: 'complete',
            loadComponent: () => import('./purchase-order-form/purchase-order-form.component'),
            canActivate: [isAccountant]
          },
          {path: '', loadComponent: () => import('./purchase-order-form/purchase-order-form.component')},
        ]
      },
      {path: '', loadComponent: () => import('./purchase-order-form/purchase-order-form.component')},
    ]
  },
  {
    path: 'notifications',
    loadComponent: () => import('./in-app-notification/in-app-notification.component'),
    canActivate: [isQhseOrDirector]
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin.component'),
    canActivate: [isAdmin]
  },
  {
    path: 'not-found',
    loadComponent: () => import('./errors/not-found/not-found.component')
  },
  {
    path: '',
    loadComponent: () => import('./dashboard/dashboard.component')
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
