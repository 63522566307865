import {WebsocketService} from "../websocket.service";
import {inject, Injectable} from "@angular/core";
import {User} from "../../models/user";
import {EMPTY, Observable} from "rxjs";
import {EventType} from "../../models/event-type";

interface WsEventDto {
  type: EventType;
  purchaseOrderUuid: string;
}

@Injectable({ providedIn: 'root' })
export class RoleTopicService {
  private websocketService = inject(WebsocketService);
  createRoleTopic(user: User): Observable<WsEventDto> {
    if (!user || !user.roles) {
      throw new Error('a user with roles is required');
    }
    let topic = null;
    if(user.roles.includes('ADMIN')) {
      topic = '/digiproc/topic/role/DIRECTOR';
    }
    if(user.roles.includes('QHSE')) {
      topic = '/digiproc/topic/role/QHSE';
    }
    if (!topic) {
      console.warn('user is not allowed to create a role topic');
      return EMPTY;
    }
    return this.websocketService.createTopic(topic, { observe: 'body' });
  }
}
