import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {PurchaseOrderApiService} from '../api/services';
import {PurchaseOrder} from '../api/models/purchase-order';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

export const purchaseOrderResolver: ResolveFn<PurchaseOrder | null> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const uuid = route.paramMap.get('uuid')!;

  return inject(PurchaseOrderApiService).getPurchaseOrder({ uuid }).pipe(
    catchError(error => {
      if (error.status === 404) {
        void router.navigate(['/not-found']);
      }
      return EMPTY;
    })
  );
};
