import {Observable} from "rxjs";
import {Client} from "@stomp/stompjs";
import {IMessage} from "@stomp/stompjs/src/i-message";

export function observableTopic(client: Client, topic: string): Observable<IMessage> {
  return new Observable((subscriber) => {
    const subscription = client.subscribe(topic, message => subscriber.next(message));
    return () => subscription.unsubscribe();
  });
}
